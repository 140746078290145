<template>
    <div style="widows: 100%; height: calc(100vh - 60px); display: flex; flex-direction: column; justify-content: center; align-items: center">
        <h1 style="font-style: italic; font-size: 4rem">这个首页导航仍在搭建中...</h1>
        <h3 style="width: 320px; margin: 0">欢迎访问：</h3>
        <ul style="width: 300px">
            <li><a href="https://gitlab.geek213.fun">🦊 GitLab - 极狐</a></li>
            <li><a href="https://r68s.geek213.fun">💿 R68s - 资源下载</a></li>
        </ul>
    </div>
    <footer style="height: 60px; line-height: 60px; text-align: center">
        <a href="https://beian.miit.gov.cn/"> 浙ICP备2021009527号-1 </a>
    </footer>
</template>
